<template>
  <div class="">
    <NavbarCatra/>
  <router-view/>
</div>
</template>

<script>
import NavbarCatra from './components/NavbarCatra.vue';

export default {
    components: {
    NavbarCatra
}
}
</script>
