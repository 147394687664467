<template>
  <div id="hero">
    <div class="pt-[100px] lg:pt-atas 2xl:px-60 flex-container bg-gradient-to-r from-[#fdfdfc] via-[#FCCFB1] to-[#fdfdfc] bg-cover">
      <div class="flex flex-wrap">
        <div class="w-full self-center pl-16 lg:w-1/2">
          <a class="text-abhu font-bold bg-lemon py-3 px-5 rounded-lg"
            >Maharati Research Institute</a
          >
          <h1 class="text-lg font-bold mt-10">
            <span class="block">WELCOME TO</span>
            <span class="text-primary">MAHARATI</span> RESEARCH INSTITUTE
            <span class="block"></span>
          </h1>
          <h2 class="text-sm font-medium mb-12">Want To Publish Journal ?</h2>
          <a
            href="https://wa.link/klshmg"
            class="transition duration-300 ease-in-out hover:bg-second text-bodas font-bold bg-primary py-4 px-5 rounded-full"
            >Consultation NOW !</a
          >
        </div>
        <div class="w-full self-end px-4 lg:w-1/2">
          <div class="mb-10">
            <img
            src="../assets/hmaharati2.png"
              alt="catra"
              class="max-w-full mx-auto"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="bg-cover bg-center bg-no-repeat h-[700px] bg-cats pt-0">
      <div class="grid grid-cols-2 gap-10 pt-15 h-[700px]">
        <div class="text-left p-60">
          <p class="text-4xl font-extrabold">
            SCIENTIFICS
            <a class="text-primary">RESEARCH</a>
          </p>
          <p class="text-4xl font-extrabold">
            JOURNAL
          </p>
          <a href="https://api.whatsapp.com/send/?phone=6281120011644&text&type=phone_number&app_absent=0">
          <button
            type="button"
            class="text-bodas bg-primary hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:bg-primary dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >Consultation NOW !</button>
        </a>
        </div>
        <div class="place-content-center">
          <div class>
            <img class="h-[629px] w-[629px]" src="../assets/home1.png" alt="homie" />
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import "@/assets/tailwind.css";

export default {};
</script>

<style>
</style>