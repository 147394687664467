<template>
  <div id="schema">
    <div class="pt-[50px] lg:pt-[150px] 2xl:px-60 flex-container">
      <div class="text-center">
        <h1 class="text-lg font-bold text-hideung pb-2">
          TRANSLATOR
          <span class="text-primary">SCHEMA</span>
        </h1>
        <h2
          class="text-md text-abhu font-normal pb-10"
        >Professional Indonesian to English and English to Indonesian translator</h2>
      </div>
      <div class="flex flex-wrap">
        <div class="w-full self-start px-4 lg:w-1/2">
          <div class="mt-5">
            <h1 class="text-md font-normal text-hideung">Scientific manuscript packet as follows:</h1>
            <div class="mt-5">
              <h1 class="mb-1">
                <img src="../assets/ceklis.png" alt="catra" class="inline h-[25px] mr-2 mb-1" />Translation of scientific articles to publish
              </h1>
              <h1 class="mb-1">
                <img src="../assets/ceklis.png" alt="catra" class="inline h-[25px] mr-2 mb-1" /> Textbook translation
              </h1>
              <h1 class>
                <img src="../assets/ceklis.png" alt="catra" class="inline h-[25px] mr-2 mb-1" />Certified
              </h1>
            </div>
          </div>
          <div class="mt-5">
            <h1 class="text-md font-normal text-hideung">Scopes of scientific manuscript packet:</h1>
            <div class="mt-5">
              <h1 class="mb-1">
                <img src="../assets/ceklis.png" alt="catra" class="inline h-[25px] mr-2 mb-1" />Technology and engineering manuscript translation
              </h1>
              <h1 class="mb-1">
                <img src="../assets/ceklis.png" alt="catra" class="inline h-[25px] mr-2 mb-1" />medical manuscript translation
              </h1>
              <h1 class="mb-1">
                <img src="../assets/ceklis.png" alt="catra" class="inline h-[25px] mr-2 mb-1" />legal manuscript translation
              </h1>
              <h1 class="mb-1">
                <img src="../assets/ceklis.png" alt="catra" class="inline h-[25px] mr-2 mb-1" />oil and gas manuscript translation
              </h1>
              <h1 class="mb-1">
                <img src="../assets/ceklis.png" alt="catra" class="inline h-[25px] mr-2 mb-1" />translation by sworn translation
              </h1>
              <h1 class="mb-1">
                <img src="../assets/ceklis.png" alt="catra" class="inline h-[25px] mr-2 mb-1" />Printed or image manuscript translation (DTP surcharge)
              </h1>
              <h1 class="mb-1">
                <img src="../assets/ceklis.png" alt="catra" class="inline h-[25px] mr-2 mb-1" />Translation with very short deadlines (rush order)
              </h1>
              <h1 class>
                <img src="../assets/ceklis.png" alt="catra" class="inline h-[25px] mr-2 mb-1" />And others
              </h1>
            </div>
          </div>
        </div>
        <div class="w-full pl-0 lg:w-1/2">
          <div class="grid mx-8 lg:mx-0 lg:grid-cols-2">
            <div
              class="border-solid border-4 border-lemon transition duration-300 ease-in-out hover:bg-lemon block max-w-[350px] rounded-[20px] shadow-lg bg-bodas mb-10 lg:mb-20 xl:w-[317px] xl:h-[432px]"
            >
              <div class="p-4">
                <h1
                  class="text-left mb-8 text-md font-medium leading-tight text-hideung"
                >Scientific Paper Package All Service</h1>
                <a
                  href="https://wa.link/klshmg"
                  class="transition duration-300 ease-in-out hover:bg-second text-bodas font-bold bg-primary py-3 px-14 rounded-full"
                >Consultation NOW !</a>
              </div>
              <div class="p-5">
              <h1 class="mb-1">
                <img src="../assets/ceklis.png" alt="catra" class="inline h-[25px] mr-2 mb-1" />Translate
              </h1>
              <h1 class="mb-1">
                <img src="../assets/ceklis.png" alt="catra" class="inline h-[25px] mr-2 mb-1" />Layout Template
              </h1>
              <h1 class="mb-1">
                <img src="../assets/ceklis.png" alt="catra" class="inline h-[25px] mr-2 mb-1" />Edit Pharafrase
              </h1>
              <h1 class="mb-1">
                <img src="../assets/ceklis.png" alt="catra" class="inline h-[25px] mr-2 mb-1" />Proofreading
              </h1>
              <h1 class="mb-1">
                <img src="../assets/ceklis.png" alt="catra" class="inline h-[25px] mr-2 mb-1" />Turnitin / IThenticate
              </h1>
              <h1 class="mb-1">
                <img src="../assets/ceklis.png" alt="catra" class="inline h-[25px] mr-2 mb-1" />Certificate License
              </h1>
              <h1 class="mb-1">
                <img src="../assets/ceklis.png" alt="catra" class="inline h-[25px] mr-2 mb-1" />Service Charge
              </h1>
            </div>
            </div>
            <div
              class="border-solid border-4 border-lemon transition duration-300 ease-in-out hover:bg-lemon block max-w-[350px] rounded-[20px] shadow-lg bg-bodas mb-10 lg:mb-20 xl:w-[317px] lg:h-[432px]"
            >
              <div class="p-4">
                <h1
                  class="text-left mb-8 text-md font-medium leading-tight text-hideung"
                >Scientific Paper Package All Service</h1>
                <a
                  href="https://wa.link/klshmg"
                  class="transition duration-300 ease-in-out hover:bg-second text-bodas font-bold bg-primary py-3 px-14 rounded-full"
                >Consultation NOW !</a>
              </div>
              <div class="p-5">
              <h1 class="mb-1">
                <img src="../assets/ceklis.png" alt="catra" class="inline h-[25px] mr-2 mb-1" />Translate
              </h1>
              <h1 class="mb-1">
                <img src="../assets/ceklis.png" alt="catra" class="inline h-[25px] mr-2 mb-1" />Layout Template
              </h1>
              <h1 class="mb-1">
                <img src="../assets/ceklis.png" alt="catra" class="inline h-[25px] mr-2 mb-1" />Proofreading
              </h1>
              <h1 class="mb-1">
                <img src="../assets/ceklis.png" alt="catra" class="inline h-[25px] mr-2 mb-1" />Service Charge
              </h1>
              <h1 class="mb-1">
                <img src="../assets/ceklis.png" alt="catra" class="inline h-[25px] mr-2 mb-1" />Certificate License
              </h1>
              <h1 class="mb-1">
                <img src="../assets/ceklis.png" alt="catra" class="inline h-[25px] mr-2 mb-1" />Conversion
              </h1>
            </div>
            </div>
          </div>
          <!-- <a class="text-abhu font-bold bg-primary py-5 pr-3 rounded-full">
              <span class="font-bold bg-second text-bodas py-5 px-6 rounded-full">1</span> DOAJ Indexing Assistance
          </a>-->
        </div>
      </div>
    </div>
  </div>
</template>
    
    <script>
import "@/assets/tailwind.css";

export default {};
</script>
    
    <style>
</style>