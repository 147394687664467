<template>
  <div id="journal">
    <div class="bg-no-repeat bg-gradient-to-r from-[#fdfdfc] via-[#FCCFB1] to-[#fdfdfc] bg-cover 2xl:px-60 lg:pb-20">
      <div class="text-center pt-[180px]">
        <h1 class="text-lg text-hideung font-bold pb-10">Our Journal</h1>
      </div>
      <div class="text-center grid grid-cols-2 gap-4 lg:grid-cols-4 pb-20">
        <div class="rounded-lg ">
          <a href="https://swastikajournal.com/index.php/cssdev" target="_blank">
            <img class="mx-auto h-[250px] lg:h-[300px] rounded-2xl transform hover:scale-105 duration-300" src="../assets/journal/11.png" />
            <div class="rounded-lg">
            <h1
              class="text-hideung lg:px-10 pt-5"
            >Community Services: Sustainability Development</h1>
          </div>
          </a>
        </div>
        <div class="rounded-lg">
          <a href="https://swastikajournal.com/index.php/jsscs" target="_blank">
            <img class="mx-auto h-[250px] lg:h-[300px] rounded-2xl transform hover:scale-105 duration-300" src="../assets/journal/22.jpg" />
            <h1 class="text-hideung lg:px-10 pt-5">Journal of Social Sciences and Cultural Studies</h1>
          </a>
        </div>
        <div class="rounded-lg">
          <a href="https://swastikajournal.com/index.php/emstdy" target="_blank">
            <img class="mx-auto h-[250px] lg:h-[300px] rounded-2xl transform hover:scale-105 duration-300" src="../assets/journal/33.png" />
            <h1 class="text-hideung lg:px-20 pt-5">Environmental Management Study</h1>
          </a>
        </div>
        <div class="rounded-lg">
          <a href="https://swastikajournal.com/index.php/jmh" target="_blank">
            <img class="mx-auto h-[250px] lg:h-[300px] rounded-2xl transform hover:scale-105 duration-300" src="../assets/journal/44.png" />

            <h1 class="text-hideung lg:px-20 pt-5">Journal Management and Hospitality</h1>
          </a>
        </div>
       
      </div>
    </div>
  </div>
</template>
  
  <script>
import "@/assets/tailwind.css";

export default {};
</script>
  
  <style>
</style>