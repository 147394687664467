<template>
  <div id="footer">
    <div class="pt-[100px] lg:pt-atas 2xl:px-60 flex-container bg-gradient-to-r from-[#fdfdfc] to-[#C87944] bg-cover">
      <div class="flex flex-wrap">
        <div class="w-full self-start lg:w-1/5">
          <div class="lg:mt-20">
            <img
              src="../assets/logomaharati.png"
              alt="catra"
              class=" max-w-full ml-10 mb-5 lg:mx-auto"
              width="250"
            />
          </div>
          <div class="grid grid-cols-4 lg:grid-cols-3">
            <div class>
              <a href="https://www.facebook.com/profile.php?id=61555755756064" target="_blank">
              <img
                src="../assets/facebook.png"
                alt="catra"
                class="rounded-[20px] ml-10 xl:ml-12 max-w-full transform hover:scale-125 duration-300"
              />
            </a>
            </div>
            <div class>
              <a href="https://www.instagram.com/maharatiresearch/" target="_blank"> 
              <img
                src="../assets/instagram.png"
                alt="catra"
                class="rounded-[20px] ml-10 lg:mx-auto max-w-full transform hover:scale-125 duration-300"
              />
            </a>
            </div>
            <div class>
              <a >
              <img
                src="../assets/twitter.png"
                alt="catra"
                class="rounded-[20px] ml-10 lg:ml-0 max-w-full transform hover:scale-125 duration-300"
              />
            </a>
            </div>
          </div>
        </div>
        <div class="w-full self-start ml-5 lg:w-1/6">
          <div class="mt-10 lg:mt-20">
            <h1 class="text-md text-abhu2 font-bold mb-5">Sitemap</h1>
            <a
              href="#hero"
              class="text-base text-abhu2 transition duration-100 ease-in-out hover:underline"
            >
              <h2 class="mb-1">Home</h2>
            </a>
            <router-link to="/blogs" custom v-slot="{ navigate }">
              <button @click="navigate" role="link">
              <a 
                class="text-base text-abhu2 transition duration-100 ease-in-out hover:underline">Blog</a></button>
            </router-link>
            <a
              href="#service"
              class="text-base text-abhu2 transition duration-100 ease-in-out hover:underline"
            >
              <h2 class="mb-1">Services</h2>
            </a>
            <a
              href="#journal"
              class="text-base text-abhu2 transition duration-100 ease-in-out hover:underline"
            >
              <h2 class="mb-1">Journal</h2>
            </a>
            <a
              href="#indexing"
              class="text-base text-abhu2 transition duration-100 ease-in-out hover:underline"
            >
              <h2 class="mb-1">Indexing</h2>
            </a>
            <a
              href="#expertise"
              class="text-base text-abhu2 transition duration-100 ease-in-out hover:underline"
            >
              <h2 class="mb-1">Expertise</h2>
            </a>
            <a
              href="#editing"
              class="text-base text-abhu2 transition duration-100 ease-in-out hover:underline"
            >
              <h2 class="mb-1">Procedures</h2>
            </a>
            <a
              href="#schema"
              class="text-base text-abhu2 transition duration-100 ease-in-out hover:underline"
            >
              <h2 class="mb-1">Translator Schema</h2>
            </a>
            <a
              href="https://drive.google.com/drive/u/0/folders/1o4xncwPio_dOFtl4uJGlGI4dPndfXTV4"
              class="text-base text-abhu2 transition duration-100 ease-in-out hover:underline"
            >
              <h2 class="mb-1">Discontinued Scopus</h2>
            </a>

          </div>
        </div>
        <div class="w-full self-start px-0 lg:w-1/4">
          <div class="mt-10 ml-10 lg:ml-0 lg:mt-20">
            <h1 class="text-md text-abhu2 font-bold mb-5">Contact</h1>
            <h1 class="mb-3 text-base text-abhu2">
              <img src="../assets/pin.png" alt="catra" class="inline h-[25px] mr-5" />Jln. Strawberry I.B No. Kelurahan Panarung, 
              <span
                class="block ml-[38px]"
              >Kecamatan Pahandut.
    Kota Palangka Raya Propinsi Kalimantan Tengah</span>
            </h1>
            <h1 class="mb-3 text-base text-abhu2">
              <img src="../assets/mail.png" alt="catra" class="inline h-[25px] mr-4" />info@swastikajournal.com
            </h1>
            <h1 class="mb-1 text-base text-abhu2">
              <img src="../assets/phone.png" alt="catra" class="inline h-[25px] mr-4" />Mobile: 0811-2002-3046
            </h1>
          </div>
        </div>
        <div class="w-full self-start px-0 lg:w-1/6">
          <div class="mt-10 ml-5 lg:ml-0 lg:mt-20">
            <h1 class="text-md text-abhu2 font-bold mb-5">Collaboration With</h1>
            <div class="ml-3 grid grid-cols-2 gap-2">
              <div class>
                <img class="h-[90px] lg:h-[60px]" src="../assets/univ1.png" alt="homie" />
              </div>
              <div class>
                <img class="h-[90px] lg:h-[60px]" src="../assets/univ2.png" alt="homie" />
              </div>
              <div class>
                <img class="h-[90px] lg:h-[60px]" src="../assets/univ3.png" alt="homie" />
              </div>
              <div class>
                <img class="h-[90px] lg:h-[60px]" src="../assets/univ4.png" alt="homie" />
              </div>
              <div class>
                <img class="h-[90px] lg:h-[60px]" src="../assets/univ5.png" alt="homie" />
              </div>
              <div class>
                <img class="h-[90px] lg:h-[60px]" src="../assets/univ6.png" alt="homie" />
              </div>
              <div class>
                <img class="h-[90px] lg:h-[70px] ml-8" src="../assets/univ7.png" alt="homie" />
              </div>
            </div>
          </div>
        </div>
        <div class="w-full self-start pl-12 lg:w-1/5">
          <div class="mt-10 lg:mt-20">
            <h1 class="text-md text-abhu2 font-bold mb-5">Supported by</h1>
            <div class>
              <!-- <img class="h-[90px] lg:h-[60px] mb-5" src="../assets/fdoaj.png" alt="homie" /> -->
              <img class="h-[90px] lg:h-[60px]" src="../assets/fdoi.png" alt="homie" />
            </div>
          </div>
        </div>
        <hr class="w-[1221px] h-[1px] mx-auto mt-10 mb-5 border-0 bg-abhu2" />
      </div>
      <a
        href="https://ahu.go.id/sabh/perseroan/qrcode/?kode=NDAyMzEwMTc2MjEwNDUwOF80Xzl1lEphbnVhcmkgMjAyNF8xOCBPa3RvYmVylDlwMjM="
        target="_blank"
        class="text-base text-center text-abhu2 transition duration-100 ease-in-out hover:underline"
      >
        <h2 class="mb-1">Copyright © 2024 PT. Batara Swastika Harati</h2>
      </a>
      <br />
    </div>

    <!-- <div class="bg-cover bg-center bg-no-repeat h-[700px] bg-cats pt-0">
        <div class="grid grid-cols-2 gap-10 pt-15 h-[700px]">
          <div class="text-left p-60">
            <p class="text-4xl font-extrabold">
              SCIENTIFICS
              <a class="text-primary">RESEARCH</a>
            </p>
            <p class="text-4xl font-extrabold">
              JOURNAL
            </p>
            <a href="https://api.whatsapp.com/send/?phone=6281120011644&text&type=phone_number&app_absent=0">
            <button
              type="button"
              class="text-bodas bg-primary hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:bg-primary dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >Consultation NOW !</button>
          </a>
          </div>
          <div class="place-content-center">
            <div class>
              <img class="h-[629px] w-[629px]" src="../assets/home1.png" alt="homie" />
            </div>
          </div>
        </div>
    </div>-->
  </div>
</template>
  
  <script>
import "@/assets/tailwind.css";

export default {};
</script>
  
  <style>
</style>